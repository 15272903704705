import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

/* NPM */
import parse from "html-react-parser"
import "aos/dist/aos.css"

/* Indie Components */
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import MountainHeader from "../components/MountainHeader"
import PageWrapper from "../components/PageWrapper"
import Section from "../components/Section"
import IndieLink from "../components/IndieLink"
import PageTitleSection from "../components/PageTitleSection"
import BodyText from "../components/BodyText"

import ContactForm from "../components/ContactForm"

const officeImage = "../assets/contact/167-great-portland-street.jpeg"

const ContactPageTemplate = ({ data: { page } }) => {
  useEffect(() => {
    const offset = window.innerWidth > 500 ? 360 : 180
    const AOS = require("aos")
    AOS.init({
      duration: 700,
      offset: offset,
      disable: function () {
        return /bot|googlebot|crawler|spider|robot|crawling/i.test(
          navigator.userAgent
        )
      },
    })

    if (AOS) {
      AOS.refresh()
    }
  }, [])
  return (
    <Layout showFooter={page.pageSettings.showFooter}>
      <Seo seo={page.seo} />
      <MountainHeader headerImages={page.headerSettings.headerImages} />
      <PageWrapper additionalClassName="contact">
        <PageTitleSection
          title={page.headerSettings.title}
          subtitle={page.headerSettings.subTitle}
        />

        <Section dataAos="fade-up" additionalClasses=" about-intro">
          <div className="container">
            <div className="column is-9">
              <div className="content-padding is-size-2 is-size-2-mobile has-text-weight-bold">
                Got an interesting project?
              </div>
              <div className="content-padding is-size-3 is-size-3-mobile has-text-weight-bold">
                Schedule a 15 minute informal chat and tell us all about it.
              </div>
              <div className="content-padding is-size-5 is-size-5-mobile has-text-weight-normal">
                <IndieLink
                  text="Book a discovery call"
                  url="/book-a-discovery-call"
                  width="20rem"
                  padding
                />
              </div>
            </div>
          </div>
        </Section>

        <Section
          dataAos="fade-up"
          additionalClasses="about-intro content-padding-address"
        >
          <div className="container">
            <div className="column is-9">
              <div className="is-size-2 is-size-2-mobile has-text-weight-bold">
                Or tell us what you need.
              </div>
              <div className="content-padding contact-form-intro is-size-3 is-size-3-mobile has-text-weight-bold">
                Fill out the form below and we’ll be in touch with lightning
                speed, almost as fast as our websites.
              </div>
            </div>
          </div>
        </Section>

        <Section additionalClasses="contact-form has-background-black-bis">
          <ContactForm />
        </Section>
        <Section>
          <div className="content-padding-address columns is-vcentered">
            <div className="column is-6">
              <StaticImage
                src={officeImage}
                alt="167-169 Great Portland Street"
                placeholder="blurred"
                layout="constrained"
                width={650}
              />
            </div>
            <div className="content-padding-address column is-offset-1 is-5 is-size-4 is-size-4-mobile has-text-centered-mobile">
              <div className="contact-address ">
                Indie Ridge Limited
                <br />
                167-169 Great Portland Street
                <br />
                5th Floor
                <br />
                London
                <br />
                W1W 5PF
                <br />
                <br />
                (+44) 0203 910 8346 <br />
                <br className="is-hidden-mobile" />
                <a href="mailto:hello@indieridge.com">hello@indieridge.com</a>
              </div>
            </div>
          </div>
        </Section>
      </PageWrapper>
    </Layout>
  )
}

export default ContactPageTemplate

export const pageQuery = graphql`
  query ContactPageById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current post by id
    page: wpPage(id: { eq: $id }) {
      ...pageFields
      contactPageFields {
        contactPageIntro
      }
    }
  }
`
