import React from "react"

const Button = ({ text, isSubmit, disabled, width = "12rem" }) => {
  return isSubmit ? (
    <>
      <button
        className="button learn-more"
        type="submit"
        disabled={disabled}
        style={{ width: width }}
      >
        <span className="circle" aria-hidden="true">
          <span className="icon arrow"></span>
        </span>
        <span className="button-text">{text}</span>
      </button>
    </>
  ) : (
    <>
      <button className="button learn-more" style={{ width: width }}>
        <span className="circle" aria-hidden="true">
          <span className="icon arrow"></span>
        </span>
        <span className="button-text">{text}</span>
      </button>
    </>
  )
}

export default Button
