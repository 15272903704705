import React from "react"
import parse from "html-react-parser"

const BodyText = ({
  text = "",
  padding = false,
  size = "is-size-3 is-size-3-mobile",
  weight = "normal",
  theme = "dark",
  additionalClasses = "",
}) => {
  const textClasses = getClass(padding, size, weight, theme, additionalClasses)

  return <div className={`${textClasses}`}>{parse(text)}</div>
}

function getClass(padding, size, weight, theme, additionalClasses) {
  var classes = ""
  classes += padding ? " content-padding" : ""
  classes += " " + size
  classes += " has-text-weight-" + weight
  classes += theme === "dark" ? " has-text-white" : " has-text-black"
  classes += additionalClasses !== "" ? " " + additionalClasses : ""
  return classes
}
export default BodyText
