import React from "react"
import { useForm, ValidationError } from "@formspree/react"
import Section from "../components/Section"
import Button from "../components/Button"

const ContactForm = () => {
  const [state, handleSubmit] = useForm("mdoyooqb")
  if (state.succeeded) {
    return (
      <Section>
        <h1 className="is-size-1 is-size-1-mobile has-text-weight-bold">
          Thank you!
        </h1>
        <h2 className="is-size-3 is-size-3-mobile has-text-weight-bold">
          You’ll hear back from us within 24 hours.
        </h2>
      </Section>
    )
  }
  return (
    <form onSubmit={handleSubmit}>
      <div className="field is-horizontal">
        <div className="field-body">
          <div className="field">
            <div className="control">
              <input
                required={true}
                id="name"
                name="name"
                className="input has-background-input has-text-white"
                type="text"
                placeholder="NAME*:"
              />
              <ValidationError
                prefix="Name"
                field="name"
                errors={state.errors}
              />
            </div>
          </div>
          <div className="field">
            <div className="control">
              <input
                required={true}
                id="company"
                name="company"
                className="input has-background-input has-text-white"
                type="text"
                placeholder="COMPANY*:"
              />
              <ValidationError
                prefix="Company "
                field="company"
                errors={state.errors}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="field is-horizontal">
        <div className="field-body">
          <div className="field">
            <div className="control">
              <input
                required={true}
                id="email"
                name="email"
                className="input has-background-input has-text-white"
                type="email"
                placeholder="EMAIL*:"
              />

              <ValidationError
                prefix="Email"
                field="email"
                errors={state.errors}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="field is-horizontal">
        <div className="field-body">
          <div className="field">
            <div className="control">
              <div class="select">
                <select
                  required={true}
                  id="budget"
                  name="budget"
                  className="input has-background-input"
                >
                  <option value="" selected>
                    WHAT IS YOUR BUDGET RANGE?*
                  </option>
                  <option>£10,000 - £25,000</option>
                  <option>£25,000 - £50,000</option>
                  <option>£25,000 - £100,000</option>
                  <option>£100,000+</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="field is-horizontal">
        <div className="field-body">
          <div className="field">
            <div className="control">
              <textarea
                required={true}
                rows="20"
                id="message"
                name="message"
                className="textarea has-background-input has-text-white"
                placeholder="LET US KNOW HOW WE CAN HELP*:"
              ></textarea>
              <ValidationError
                prefix="Message"
                field="message"
                errors={state.errors}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="content-padding has-text-centered">
        <Button
          isSubmit={false}
          text="Send"
          disabled={state.submitting}
          width="9rem"
        />
      </div>
    </form>
  )
}

export default ContactForm
